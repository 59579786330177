import React, { useState } from 'react';
import axios from 'axios';

import './CreateProject.scss';
import { Error } from '../Error/Error.jsx';
import { Loader } from '../Loader/Loader.jsx';
import { API_URL } from '../../config';
import { TextEditor } from '../TextEditor/TextEditor';

export const CreateProject = () => {
	//Crear loader:
	const [loading, setLoading] = useState(false);
	//Crear error:
	const [error, setError] = useState(null);
	//Mensaje de éxito:
	const [success, setSuccess] = useState(null);
	//Crear estado para guardar los datos del formulario:
	const initialState = {
		name: '',
		title: '',
		description: '',
		cover: null,
		image: null,
		image_2: null,
		video: null,
		audio: null,
		external_video: '',
		external_link: '',
		external_link_name: '',
		bandcamp: '',
	};
	const [formData, setFormData] = useState(initialState);
	const [description, setDescription] = useState('');

	const handleChange = (event) => {
		const { name, value, files } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: files ? files[0] : value,
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			setLoading(true);
			setSuccess(null);
			setError(null);

			//Validar los datos del formulario: TODO: habría que añadir más validaciones
			if (
				!formData.name ||
				!formData.title ||
				!description ||
				!formData.cover
			) {
				setError(
					'El nombre, título, descripción y la imagen de portada son obligatorios'
				);
				return;
			}

			//Limitar el tamaño de imágenes a 5MB:
			if (formData.cover.size > 5000000) {
				setError('El tamaño máximo de la imagen de portada es de 5MB');
				return;
			}
			if (formData.image && formData.image.size > 5000000) {
				setError('El tamaño máximo de la imagen 1 es de 5MB');
				return;
			}
			if (formData.image_2 && formData.image_2.size > 5000000) {
				setError('El tamaño máximo de la imagen 2 es de 5MB');
				return;
			}

			//Validar el tamaño máximos de los vídeos y audios a 50MB:
			if (formData.video && formData.video.size > 50000000) {
				setError('El tamaño máximo del vídeo es de 50MB');
				return;
			}
			if (formData.audio && formData.audio.size > 50000000) {
				setError('El tamaño máximo del audio es de 50MB');
				return;
			}

			const formDataToSend = new FormData();
			formDataToSend.append('name', formData.name);
			formDataToSend.append('title', formData.title);
			formDataToSend.append('description', description);
			formDataToSend.append('cover', formData.cover);
			formDataToSend.append('image', formData.image);
			formDataToSend.append('image_2', formData.image_2);
			formDataToSend.append('video', formData.video);
			formDataToSend.append('audio', formData.audio);
			formDataToSend.append('external_video', formData.external_video);
			formDataToSend.append('external_link', formData.external_link);
			formDataToSend.append(
				'external_link_name',
				formData.external_link_name
			);
			formDataToSend.append('bandcamp', formData.bandcamp);

			//Hacer la petición con axios:
			const { data: response } = await axios.post(
				`${API_URL}/projects`,
				formDataToSend,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			//console.log(response.data);
			//Mensaje de éxito:
			setSuccess('Proyecto creado con éxito');

			//Si todo va bien: Resetear el formulario: (los tipo file no se pueden resetear ya que se trata de elementos no controlados que sólo pueden ser definidos por el usuario)
			setFormData((prevState) => ({
				...prevState,
				name: '',
				title: '',
				description: '',
				external_video: '',
				external_link: '',
				external_link_name: '',
				bandcamp: '',
			}));

			//TODO: Manejar las respuestas según el status code
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			//Quitar el loader:
			setLoading(false);
		}
	};

	return (
		<div className='CreateProject'>
			<form className='CreateProject-form' onSubmit={handleSubmit}>
				{/* Mensajes de error de validación: */}
				{error && <Error error={error} setError={setError} />}
				{loading && <Loader />}
				{success && <p style={{ color: 'green' }}>{success}</p>}

				<h3>CREAR PROYECTO</h3>
				<label>Nombre:</label>
				<input
					type='text'
					name='name'
					onChange={handleChange}
					value={formData.name}
				/>

				<label>Título:</label>
				<input
					type='text'
					name='title'
					onChange={handleChange}
					value={formData.title}
				/>

				<label>Descripción:</label>
				<TextEditor
					editorState={description}
					setEditorState={setDescription}
				/>

				<label>Imagen de portada:</label>
				<input
					type='file'
					name='cover'
					onChange={handleChange}
					accept='image/*'
				/>

				<label>Imagen 1:</label>
				<input
					type='file'
					name='image'
					onChange={handleChange}
					accept='image/*'
				/>

				<label>Imagen 2:</label>
				<input
					type='file'
					name='image_2'
					onChange={handleChange}
					accept='image/*'
				/>

				<label>Video:</label>
				<input
					type='file'
					name='video'
					onChange={handleChange}
					accept='video/*'
				/>

				<label>Audio:</label>
				<input
					type='file'
					name='audio'
					onChange={handleChange}
					accept='audio/*'
				/>

				<label>Enlace a video externo:</label>
				<input
					type='text'
					name='external_video'
					onChange={handleChange}
					value={formData.external_video}
				/>

				<label>Enlace externo:</label>
				<input
					type='text'
					name='external_link'
					onChange={handleChange}
					value={formData.external_link}
				/>

				<label>Nombre enlace externo:</label>
				<input
					type='text'
					name='external_link_name'
					onChange={handleChange}
					value={formData.external_link_name}
				/>

				<label>Bandcamp:</label>
				<input
					type='text'
					name='bandcamp'
					onChange={handleChange}
					value={formData.bandcamp}
				/>

				<button type='submit'>Enviar</button>
			</form>
		</div>
	);
};
