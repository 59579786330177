import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './Project.scss';
// import { projects } from '../Projects/projects';
import axios from 'axios';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import { API_URL } from '../../config';

export const Project = () => {
	const { id } = useParams();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [project, setProject] = useState(null);

	// console.log(project);

	const getProject = async () => {
		setLoading(true);
		setError(null);
		const url = `${API_URL}/projects/${id}`;

		try {
			const { data: response } = await axios.get(url);
			// console.log(response.data);
			setProject(response.data);
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getProject();
	}, [setProject]); // eslint-disable-line

	return (
		<div className='Project'>
			{loading && <Loader />}
			{error && <Error message={error} />}
			{project && (
				<>
					<div className='project-media-container'>
						{project.video && (
							<video
								src={project.video.secure_url}
								controls
							></video>
						)}
						{project.image && (
							<div className='project-img-container'>
								<img
									src={project.image.secure_url}
									alt={project.name}
									key={project.public_id}
								/>
								<img
									src={project.image_2.secure_url}
									alt={project.name}
									key={project.public_id}
								/>
							</div>
						)}
						{project.external_video && (
							<div
								className='external-video'
								dangerouslySetInnerHTML={{
									__html: project.external_video,
								}}
							></div>
						)}

						<div className='audio-link-container'>
							{!project.audio ? null : (
								<audio controls>
									<source
										src={project.audio.secure_url}
										type='audio/mpeg'
									/>
									Your browser does not support the audio
									element.
								</audio>
							)}
							{!project.external_link ? null : (
								<a
									href={project.external_link}
									target='_blank'
									rel='noreferrer'
								>
									{project.external_link_name}
								</a>
							)}
							{!project.bandcamp ? null : (
								<div
									dangerouslySetInnerHTML={{
										__html: project.bandcamp,
									}}
								></div>
							)}
						</div>
					</div>
					<div className='project-text-container'>
						<div className='project-text-inner-container'>
							{/* <h5>{project.title}</h5> */}
							{/* <p>{project.description}</p> */}
							<div
								dangerouslySetInnerHTML={{
									__html: project.description,
								}}
							></div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
