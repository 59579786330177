import React from 'react';
import './Dashboard.scss';
import { CreateProject } from '../../components/CreateProject/CreateProject';
import { ProjectList } from '../../components/ProjectList/ProjectList';
import { CreateAbout } from '../../components/CreateAbout/CreateAbout';
import { CreateStudio } from '../../components/CreateStudio/CreateStudio';
import { Logout } from '../../components/Logout/Logout';
import { AboutCategoriesList } from '../../components/AboutCategoriesList/AboutCategoriesList';

export const Dashboard = () => {
	return (
		<div className='Dashboard'>
			<Logout />
			<h1>Dashboard</h1>

			<details>
				<summary>Crear un proyecto</summary>
				<CreateProject />
			</details>

			<details>
				<summary>Editar proyectos</summary>
				<ProjectList />
			</details>

			<details>
				<summary>Crear una categoría (About)</summary>
				<CreateAbout />
			</details>

			<details>
				<summary>Editar una categoría (About)</summary>
				<AboutCategoriesList />
			</details>

			<details>
				<summary>Crear/Editar studio</summary>
				<CreateStudio />
			</details>
		</div>
	);
};
