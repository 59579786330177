import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './CreateAbout.scss';
import { Error } from '../Error/Error.jsx';
import { Loader } from '../Loader/Loader.jsx';
import { API_URL } from '../../config';
import { TextEditor } from '../TextEditor/TextEditor';

export const CreateAbout = () => {
	//Crear loader:
	const [loading, setLoading] = useState(false);
	//Crear error:
	const [error, setError] = useState(null);
	//Mensaje de éxito:
	const [success, setSuccess] = useState(null);
	//Crear estado para guardar los datos del formulario:
	const initialState = {
		category: '',
		title: '',
		description: '',
	};

	const [data, setData] = useState(initialState);
	const [description, setDescription] = useState('');

	//console.log(data);

	const handleChange = (event) => {
		const { name, value, files } = event.target;
		setData((prevState) => ({
			...prevState,
			[name]: files ? files[0] : value,
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			setLoading(true);
			setSuccess(null);
			setError(null);

			//Validar los datos del formulario: TODO: habría que añadir más validaciones
			if (!data.category || !data.title || !description) {
				setError(
					'La categoría, el título y la descripción son obligatorios'
				);
				return;
			}

			//console.log(data.category, data.title, description);

			//Hacer la petición con axios:
			const { data: response } = await axios.post(`${API_URL}/about`, {
				category: data.category,
				title: data.title,
				description: description,
			});

			console.log(response.data);
			//Mensaje de éxito:
			setSuccess('Categoría creada con éxito');

			//Si todo va bien: Resetear el formulario: (los tipo file no se pueden resetear ya que se trata de elementos no controlados que sólo pueden ser definidos por el usuario)
			setData((prevState) => ({
				...prevState,
				category: '',
				title: '',
			}));
			setDescription('');

			//TODO: Manejar las respuestas según el status code
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			//Quitar el loader:
			setLoading(false);
		}
	};

	return (
		<div className='CreateAbout'>
			<form className='CreateAbout-form' onSubmit={handleSubmit}>
				{/* Mensajes de error de validación: */}
				{error && <Error error={error} setError={setError} />}
				{loading && <Loader />}
				{success && <p style={{ color: 'green' }}>{success}</p>}

				<h3>CREAR CATEGORÍA</h3>
				<label>Categoría:</label>
				<input
					type='text'
					name='category'
					onChange={handleChange}
					value={data.category}
				/>

				<label>Título:</label>
				<input
					type='text'
					name='title'
					onChange={handleChange}
					value={data.title}
				/>

				<label>Descripción:</label>
				<TextEditor
					editorState={description}
					setEditorState={setDescription}
				/>

				<button type='submit'>Enviar</button>
			</form>
		</div>
	);
};
